/* import __COLOCATED_TEMPLATE__ from './more-sources.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { AiContentState } from 'embercom/lib/ai-content-library/constants';
import { type KnowledgeHubApiQueryParams } from 'embercom/lib/knowledge-hub/list-api';
import { EntityType } from 'embercom/models/data/entity-types';
import { objectIcons } from 'embercom/models/data/matching-system/matching-constants';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { type OverviewSectionArgs } from '../../overview';
import { type ContentFilter } from '../types';
import { type SourceRow } from './section';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type SyncSourceWrapper from 'embercom/models/knowledge-hub/sync-source-wrapper';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

type EntityTypeConfig = {
  entityType: EntityType;
  visibleFor: ContentFilter[];
  customOnActionButtonClick?: () => void;
};

let syncedSources = ['box', 'document360'];

const PAYWALLS: Partial<Record<EntityType, { paywallFeatureKey: string }>> = {};

export default class MoreSources extends Component<OverviewSectionArgs> {
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare store: any;
  @service declare appService: {
    app: {
      canUseStandalone: boolean;
      canUseIntegrationAppForSalesforce: boolean;
      canUseFeature(featureFlagName: string): boolean;
    };
  };

  get entityTypesConfig(): EntityTypeConfig[] {
    let entityTypes: EntityTypeConfig[] = [
      {
        entityType: EntityType.ContentSnippet,
        visibleFor: ['all', 'ai-agent', 'ai-copilot'],
      },
      {
        entityType: EntityType.FileSourceContent,
        visibleFor: ['all', 'ai-agent', 'ai-copilot'],
        customOnActionButtonClick: () => this.args.onAddSource('file-upload'),
      },
      {
        entityType: EntityType.Answer,
        visibleFor: ['all', 'ai-agent'],
      },
    ];

    if (
      this.appService.app.canUseStandalone &&
      !this.appService.app.canUseFeature('team-standalone-custom-answers')
    ) {
      entityTypes = entityTypes.filter((type) => type.entityType !== EntityType.Answer);
    }

    return entityTypes;
  }

  get filteredEntityTypes() {
    return this.entityTypesConfig.filter((config) =>
      config.visibleFor.includes(this.args.contentFilter),
    );
  }

  get rows(): SourceRow[] {
    return this.filteredEntityTypes.flatMap((config) => this.allContentRow(config));
  }

  syncSourceTitle(syncSource: SyncSourceWrapper) {
    if (syncSource.title) {
      return `${this.intl.t(`knowledge-hub.overview.sections.syncs.types.${syncSource.sourceType}`)} - ${syncSource.title}`;
    }
    return this.intl.t(`knowledge-hub.overview.sections.syncs.types.${syncSource.sourceType}`);
  }

  private createMainContentRow({
    entityType,
    customOnActionButtonClick,
  }: EntityTypeConfig): SourceRow {
    return {
      icon: objectIcons[entityType],
      statusIcon: this.contentCount(entityType) > 0 ? 'check' : undefined,
      title: this.intl.t(`knowledge-hub.overview.sections.${entityType}.rows.active.title`),
      onTitleClick: () =>
        this.knowledgeHubService.goToContent(entityType, this.AIFilterQueryParams),
      status: this.getContentStatus(entityType),
      onActionButtonClick: () =>
        this.handleActionButtonClick(entityType, customOnActionButtonClick),
      actionButtonLabel: this.intl.t(
        `knowledge-hub.overview.sections.${entityType}.rows.active.action.label`,
      ),
      paywallFeatureKey: PAYWALLS[entityType]?.paywallFeatureKey,
    };
  }

  private createSyncSourceRow(source: any, entityType: EntityType): SourceRow {
    let syncSource = this.createSyncSourceWrapper(source);

    return {
      statusIcon: this.getSyncSourceStatusIcon(source),
      iconUrl: assetUrl(`/assets/images/knowledge-hub/icons/${source.source_type}.png`),
      title: this.syncSourceTitle(syncSource),
      onTitleClick: () => {
        if (syncedSources.includes(source.source_type) && syncSource.folderId) {
          this.knowledgeHubService.goToSyncSourceFolder(
            syncSource,
            {
              ...this.AIFilterQueryParams,
            },
            false,
            false,
          );
        } else {
          this.knowledgeHubService.goToContent(entityType, {
            ...this.AIFilterQueryParams,
          });
        }
      },
      status: this.getSyncSourceStatus(source, entityType),
      hasOptions: true,
      syncSource,
    };
  }

  private getSyncSourceStatusIcon(source: any): InterfaceIconName | undefined {
    switch (source.status) {
      case 'live':
        return source.usage?.all > 0 ? 'check' : undefined;
      case 'syncing':
        return 'sync';
      case 'error':
        return 'warning';
      default:
        return undefined;
    }
  }

  private getSyncSourceStatus(source: any, entityType: EntityType): string {
    if (source.status === 'error') {
      return this.intl.t('knowledge-hub.overview.sections.syncs.rows.errors.sync_error');
    } else if (source.status === 'syncing') {
      return this.intl.t('knowledge-hub.overview.sections.syncs.rows.active.description.syncing');
    }
    return this.intl.t(`knowledge-hub.overview.sections.${entityType}.rows.active.description`, {
      count: source.usage?.all || 0,
      contentFilter: '',
    });
  }

  private createSyncSourceWrapper(source: any): SyncSourceWrapper {
    let id = `${source.source_type}-${source.source_id}`;
    let normalizedObject = this.store.normalize('knowledge-hub/sync-source-wrapper', {
      ...source,
      id,
    });
    return this.store.push(normalizedObject) as SyncSourceWrapper;
  }

  private getContentStatus(entityType: EntityType): string {
    return this.intl.t(`knowledge-hub.overview.sections.${entityType}.rows.active.description`, {
      count: this.contentCount(entityType),
      contentFilter: this.getContentFilter(),
    });
  }

  private getContentFilter(): string {
    return this.appService.app.canUseStandalone
      ? ''
      : this.intl.t(`knowledge-hub.overview.sections.content-filter.${this.args.contentFilter}`);
  }

  private handleActionButtonClick(
    entityType: EntityType,
    customOnActionButtonClick?: () => void,
  ): void {
    if (customOnActionButtonClick) {
      customOnActionButtonClick();
    } else {
      this.knowledgeHubService.createContent(entityType);
    }
  }

  allContentRow({ entityType, customOnActionButtonClick }: EntityTypeConfig): SourceRow[] {
    let rows: SourceRow[] = [
      this.createMainContentRow({
        entityType,
        customOnActionButtonClick,
        visibleFor: ['all', 'ai-agent', 'ai-copilot'],
      }),
    ];

    let contentSummary = this.args.knowledgeUsageSummary?.[entityType];
    if (contentSummary?.sync_sources?.length > 0) {
      let syncRows = contentSummary.sync_sources.map((source) =>
        this.createSyncSourceRow(source, entityType),
      );
      rows.push(...syncRows);
    }

    return rows;
  }

  get AIFilterQueryParams(): KnowledgeHubApiQueryParams {
    if (this.args.contentFilter === 'ai-agent') {
      return {
        chatbotState: AiContentState.USED_BY_FIN,
      };
    } else if (this.args.contentFilter === 'ai-copilot') {
      return {
        copilotState: AiContentState.USED_BY_FIN,
      };
    }
    return {};
  }

  contentCount(entityType: EntityType) {
    let contentSummary = this.args.knowledgeUsageSummary?.[entityType];
    if (!contentSummary) {
      return 0;
    }
    let { agent, copilot, all } = contentSummary;
    switch (this.args.contentFilter) {
      case 'all':
        return all || 0;
      case 'ai-agent':
        return agent || 0;
      case 'ai-copilot':
        return copilot || 0;
      case 'help-center':
        return all || 0;
      default:
        return 0;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Sections::MoreSources': typeof MoreSources;
    'knowledge-hub/overview/sections/more-sources': typeof MoreSources;
  }
}
